<template>
  <div>
    <cookie
      :cookietext="cookietext"
      :cookiebutton="cookiebutton"
      :cookietextcolor="cookietextcolor"
      :cookietextsize="cookietextsize"
      :cookiebackgroundcolor="cookiebackgroundcolor"
      :cookiename="cookiename"
      :cookievalue="cookievalue"
      :cookieexdays="cookieexdays"
    ></cookie>
  </div>
</template>

<script>
import cookie from "vue-cookie-notification"

export default {
  name: "cookies",
  components: {
    cookie,
  },
  data() {
    return {
      cookietext:
        "We use cookies to improve your experience and personalise the content you see. You can either allow all cookies or manage them individually. It’s your call. Click to find out more about our Cookie Policy here.",
      cookiebutton: "Accept",
      cookietextcolor: "white",
      cookietextsize: "14px",
      cookiebackgroundcolor: "gray",
      cookiename: "VUECOOKIE",
      cookievalue: "12345",
      cookieexdays: 365,
    }
  },
}
</script>
