<template>
  <v-card class="mx-auto" max-width="344">
    <v-card-text>
      <div>Get in touch</div>
      <p class="text-h6 text--primary">AWM Telco</p>
      <p>adjective</p>
      <div class="text--primary">
        well meaning and kindly.<br />
        "a benevolent smile"
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="deep-purple accent-4"> Learn More </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {};
</script>
